<template>
  <div class="property">
    <div class="label">{{ title }}</div>
    <div class="complex-property">
      <div class="description">
        {{ $t('process_editor.element_properties.user_task.has', [fields.length]) }}
      </div>

      <el-button
        class="el-button--square"
        type="primary"
        plain
        icon="el-icon-edit"
        @click="onEdit()"
        size="mini"
      ></el-button>

      <el-dialog
        v-if="editFields"
        :visible.sync="visible"
        :modal="false"
        :close-on-click-modal="false"
        class="custom-dialog__wrapper"
        custom-class="custom-dialog"
        @close="onClose"
        width="50%"
      >
        <template slot="title">
          <div class="el-dialog__title">
            <el-button
              type="primary"
              icon="el-icon-plus"
              class="el-button--square"
              plain
              @click="onAdd()"
              size="mini"
              style="margin-right: 10px;"
            ></el-button>
            {{ title }}
          </div>
        </template>

        <el-form class="field-mapping-form custom_scrollbar" ref="form" label-position="top" size="mini">
          <el-form class="field-mapper-form" label-position="top" size="mini"
                   v-for="(item, index) in editFields"
                   :key="index">
            <div class="line">
              <el-row :gutter="10">
                <el-col :span="hideType ? 12 : 6">
                  <el-form-item>
                    <el-input v-model="item.field" :placeholder="$t('main.fields.name')"/>
                  </el-form-item>
                </el-col>

                <el-col v-if="!hideType" :span="6">
                  <el-form-item>
                    <el-select
                      size="mini"
                      v-model="item.type"
                      :placeholder="$t('task_editor.labels.field_type')"
                    >
                      <el-option
                        v-for="item in Object.keys(ruleFieldTypes).filter((_) => availableTypes.includes(_))"
                        :key="item"
                        :label="ruleFieldTypes[item]"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item>
                    <el-input v-model="item.value" :placeholder="$t('main.fields.value')"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-button
                class="el-button--square"
                type="danger"
                plain
                icon="el-icon-delete"
                @click="onRemove(index)"
                size="mini"
                style="margin-left: 10px;"
              ></el-button>
            </div>
            <el-input v-if="showNotes" size="mini" :placeholder="$t('registry.approvals.comment')" v-model="item.notes" type="textarea" class="notes"/>
          </el-form>
        </el-form>

        <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel()" size="mini">
          {{ $t('main.button.cancel') }}
        </el-button>

        <el-button type="primary" @click="onAccept()" :disabled="acceptDisabled" size="mini">
          {{ $t('main.button.accept') }}
        </el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import FieldMapperForm
  from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/PropertyEditors/UserTaskEditor/FieldMapping/FieldMapperForm.vue'

const cloneObject = function (value) {
  return JSON.parse(
    JSON.stringify(value)
  )
}

export default {
  name: 'VariablesBlock',
  components: { FieldMapperForm, RegistrySelectTree },
  props: {
    title: String,
    name: String,
    extensions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    hideType: {
      type: Boolean,
      default: false
    },
    showNotes: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    acceptDisabled () {
      if (!this.editFields || !this.fields) {
        return true
      }

      return JSON.stringify(this.fields) === JSON.stringify(this.editFields)
    }
  },

  mounted () {
    const extensions = this.prepareExtensions(this.extensions)
    let fields = []
    Object.keys(extensions[this.name] || {}).forEach((field) => {
      if (typeof (extensions[this.name][field] || false) === 'object') {
        fields.push({
          field: field,
          type: extensions[this.name][field].type,
          value: extensions[this.name][field].value,
          notes: extensions[this.name][field].notes
        })
      }
    })

    this.fields = fields
  },

  data () {
    return {
      visible: false,
      fields: [],
      editFields: null,
      historyFields: null,
      ruleFieldTypes: this.$t('process_editor.element_properties.user_task.rule_field_types'),
      availableTypes: ['constant', 'parameter']
    }
  },

  methods: {
    prepareExtensions (obj) {
      const result = {}

      // For each object path (property key) in the object
      for (const objectPath in obj) {
        // Split path into component parts
        const parts = objectPath.split('.')

        // Create sub-objects along path as needed
        let target = result
        while (parts.length > 1) {
          const part = parts.shift()
          target = target[part] = target[part] || {}
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath]
      }

      return result
    },

    onAccept () {
      this.fields = cloneObject(this.editFields)
      this.emitChanges()
    },

    onEdit () {
      this.visible = true
      this.editFields = cloneObject(this.fields)
      this.historyFields = cloneObject(this.fields)
    },

    onClose () {
      this.visible = false
      this.editFields = null
      this.historyFields = null
    },

    onCancel () {
      this.visible = false
      this.fields = cloneObject(this.historyFields)
      this.editFields = null
      this.historyFields = null
    },

    onAdd () {
      if (!this.editFields || !Array.isArray(this.editFields)) {
        return
      }

      this.editFields.push({
        field: null,
        type: null
      })
    },

    onRemove (index) {
      if (!this.editFields || !Array.isArray(this.editFields)) {
        return
      }

      this.editFields.splice(index, 1)
    },

    changeField (data, index) {
      this.$set(this.editFields, index, data)
    },

    emitChanges () {
      this.$emit('remove-extension', { filter: (_) => _.indexOf(this.name + '.') === 0 })
      this.fields.forEach((item) => {
        if (item.field) {
          if (item.type) {
            this.$emit('change-extension', { name: `${this.name}.${item.field}.type`, value: item.type })
          }
          if (item.value) {
            this.$emit('change-extension', { name: `${this.name}.${item.field}.value`, value: item.value })
          }
          if (item.notes) {
            this.$emit('change-extension', { name: `${this.name}.${item.field}.notes`, value: item.notes })
          }
        }
      })
    }
  }
}
</script>
